:root {
  --body: 800px;
  --whole: 2118.521728515625px;
  --rest: calc(var(--whole) - var(--body));
  --irest: calc(var(--body) - var(--whole));
  --bez: cubic-bezier(0.8, 0.79, 1, 0.935);
  --bodyS: 600px;
  --restS: calc(var(--whole) - var(--bodyS));
  --irestS: calc(var(--bodyS) - var(--whole));

  --midnightPurple: #1a021d;
  --accentPurple: #b380ff;
  --wrigglerRed: #ff0040;
  --radii-base: 0.25rem;
  --felixGreen: #adff2f;
  --transitions-1: all cubic-bezier(0.4, 0, 0.2, 1) 150ms;
  --opensea: #ffffff;
}

html {
  background: var(--midnightPurple);
  font-size: 1rem;
  line-height: 1.5rem;
}

@font-face {
  font-family: 'Azeret Mono';
  src: url(./fonts/AzeretMono-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'DePixel';
  src: url(./fonts/DePixelHalbfett.ttf) format('truetype');
}

* {
  font-family: "Azeret Mono", Monaco;
}

